import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import Certified from "../../assets/home/toronto-plumber.svg";
import Design from "../../images/how-it-works-backwater-valve.gif";
import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "0px 80px 0px 80px",
    marginTop: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },

  
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
        style={{ paddingTop: "50px" }}
      >
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Certified alt="toronto-plumber" height="100%" width="100%" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={7}>
          <h2 className={classes.title} style={{ fontWeight: "bold" }}>
            Prevent Backflow
          </h2>
          <p className={classes.paragraph2}>
            A backwater valve will stop water or sewage from flowing into your
            house. Our certified team of plumbers will apply for a permit and
            install the backwater valve. The valve will be provided with a clear
            top to allow you to inspect the operation of the valve.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
        style={{ paddingTop: "150px", paddingBottom: "100px" }}
      >
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={5}
          className={classes.orderContainer}
        >
          <h2 className={classes.title}> How it Works</h2>
          <p className={classes.paragraph2}>
            In the event a major sewer backup from the city pipes, homes that do
            not have a backwater valve installed on their main drain pipe are at
            a greater risk basement flooding. A backwater valve will prevent the
            water and sewer backup from flowing back into your home. When the
            water level is high enough, the flap will rise and close the valve
            to prevent back-up.
          </p>
        </Grid>
        <Grid item lg={4} xs={10} sm={8} md={8}>
          <div align="center">
            <img
              src={Design}
              alt="toronto-plumbers"
              style={{
                width: "100%",
                paddingBottom: "15px",
                borderRadius: "5px",
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
